import React, {Component} from "react";
import Layout from "../components/layout";
import {graphql} from "gatsby";
import {buildBlocks} from "../services/builder";
import {CoreFields} from "./fragment";  // eslint-disable-line
import {CoreBlockFragment} from "../components/blocks/CoreBlock";  // eslint-disable-line

class Job extends Component {
    render() {
      const { title } = this.props.data.wpJob;

      this.props.pageContext.pageTitle = title;
      this.props.pageContext.job = this.props.data.wpJob;

      const blocks = buildBlocks( this.props.data.wpPage.blocks, { }, { ...this.props.pageContext } );

      return (
      <Layout
        meta={this.props.data.wpJob}
        path={this.props.pageContext.pagePath}
        title={title}
        themeOptions={this.props.data.wp.themeOptions}
      >
        {blocks}
      </Layout>
      )
    }
}

export const query = graphql`
  query ($id: String, $jobPage: String) {
    wpPage(slug: {eq: $jobPage}) {
      blocks {
        ...CoreBlock
        ...CoreFields
        innerBlocks {
          ...CoreBlock
          ...CoreFields
          innerBlocks {
            ...CoreBlock
            ...CoreFields
            innerBlocks {
              ...CoreBlock
              ...CoreFields
              innerBlocks {
                ...CoreBlock
                ...CoreFields
              }
            }
          }
        }
      }
    }
    wpJob(id: {eq: $id}) {
      id
      title
      Jobs {
        jobDetails
        introductionSnippet
        applicationEmail
      }
    }
    wp {
      themeOptions {
        ThemeOptions {
          siteLogo {
            mediaItemUrl
          }
        }
      }
    }
}
`;

export default Job
